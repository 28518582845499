// 酒店报表
import req_hotel_statement_data from "@/lib/data-service/haolv-default/consumer_t_od_hotel_statement_queryHotelStatement";
// 酒店报表部门列表
import req_departmentList from "@/lib/data-service/haolv-default/consumer_department_getDepartmentList";

export default {
  data() {
    return {
      loading: false,
      form: {
        deptId: [""],
        startDate: "",
        endDate: "",
      },
      detail: {},
      hotelOptions: {},
      dateRange: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      departmentList: [],
    };
  },
  components: {},
  created() {},
  activated() {},
  methods: {
    req_depart() {
      req_departmentList().then((res) => {
        let departmentList = res.datas.departmentListResults;
        departmentList.unshift({ deptName: "全部", deptId: "", childrenList: [] });
        this.departmentList = departmentList;
      });
    },
    req_datas() {
      if (this.loading) return;
      this.loading = true;
      this.detail = {};
      this.hotelOptions = {};
      let params = { ...this.form };
      params.deptId = params.deptId.length
        ? params.deptId[params.deptId.length - 1]
        : "";
      req_hotel_statement_data(params)
        .then((res) => {
          this.detail = res;
          const options = res.hotelStatementResultList;

          let starText = [];

          let countList = [];
          let roomNumList = [];

          options.forEach((item) => {
            const star = Number(item.hotelStar);
            if (star === 20) {
              starText.push("经济型");
            } else if (star === 25 || star === 30) {
              starText.push("舒适型");
            } else if (star === 35 || star === 40) {
              starText.push("高档型");
            } else if (star === 45 || star === 50 || star === 55) {
              starText.push("豪华型");
            }
            countList.push(item.expenditureTotal);
            roomNumList.push(item.roomNum);
          });
          const colorList = ["#A8C599", "#1764C9", "#CCBEA0", "#ff6f21"];

          this.hotelOptions = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999",
                },
              },
            },
            xAxis: [
              {
                type: "category",
                data: starText,
                axisPointer: {
                  type: "shadow",
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: "房间数",
                axisLabel: {
                  formatter: "{value} 间",
                },
              },
              {
                type: "value",
                name: "消费金额",
                axisLabel: {
                  formatter: "{value} 元",
                },
              },
            ],
            series: [
              {
                name: "房间数",
                type: "bar",
                barMaxWidth: "15",
                data: roomNumList,
                itemStyle: {
                  normal: {
                    color: function(params) {
                      return colorList[params.dataIndex];
                    },
                  },
                },
              },
              {
                name: "消费金额",
                type: "bar",
                yAxisIndex: 1,
                barMaxWidth: "15",
                data: countList,
                itemStyle: {
                  normal: {
                    color: function(params) {
                      return colorList[params.dataIndex];
                    },
                  },
                },
              },
            ],
          };

          this.hotelOptions = hotelOptions;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    init() {
      this.req_depart();
      this.req_datas();
    },
    change_date(val) {
      const form = this.form;
      if (val) {
        form.startDate = val[0]+'-01';
        let endDate = this.$moment(val[1]).endOf('month').format('YYYY-MM-DD');
        form.endDate = endDate;
      } else {
        form.startDate = "";
        form.endDate = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
